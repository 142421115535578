.container_proyectos{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 3vh;
    flex-direction: column;
}


.menu_proyectos{
  margin-bottom: 2vh;
}

.menu_proyectos ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 1.5vh;
}

.menu_proyectos li {
  padding: 8px 12px;
  cursor: pointer;
  transition: 0.3s;
  font-family: var(--fuente-cuerpo);
  font-size: 20px;
  color: var(--primary-900);
}

.separator{
  width: 1.5px; 
  height: 41px;
  background-color: var(--primary-900);
}

.menu_proyectos li:hover{
  color: var(--secondary-600);
  font-weight: 700;
}

.menu_proyectos li.active {
  color: var(--secondary-600);
  font-weight: 700;
  border-radius: 5px;
}

.menu_proyectos {
  margin-bottom: 2vh;
  width: 100%;
  padding: 0 5vw;
}

.menu_proyectos ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0; /* eliminamos el gap general */
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu_proyectos li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--fuente-cuerpo);
  font-size: 20px;
  color: var(--primary-900);
  white-space: nowrap;
  position: relative;
  text-align: center;
}

.menu_proyectos li::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -6px; /* separa un poco la línea del texto */
  height: 100%;
  width: 1px; /* grosor de la línea */
  background-color: #003DA5; /* azul del header */

}

/* Quitar la línea después del último */
.menu_proyectos li:last-child::after {
  content: none;
}

.menu_proyectos li:hover,
.menu_proyectos li.active {
  color: var(--secondary-600);
  font-weight: 700;
}

/* Tablet (2 filas de 3 columnas) */
@media (max-width: 1024px) {
  .menu_proyectos ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 0;
    justify-items: center;
  }

  .menu_proyectos li {
    font-size: 18px;
  }

  .menu_proyectos li::after {
    right: -8px;
  }
}

/* Mobile (menú en varias líneas con separadores) */
@media (max-width: 768px) {
  .menu_proyectos ul {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu_proyectos li {
    font-size: 16px;
  }
}
