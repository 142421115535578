/* Estilos generales */
.services-page-container {
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
  }
  
  .services-header {
    text-align: center;
    padding: 20px 0;
  }
  
  .services-title {
    font-size: 2rem;
    color: #0D47A1;
  }
  
  /* SECCIÓN SERVICIOS */
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px;
  }
  
  /* TARJETAS DE SERVICIO EN WEB */
  .service-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: grad;
    overflow: hidden;
    width: 80%;
    max-width: 1100px;
    min-height: 350px;
    box-shadow: 0px 4px 6px rgba(0, 0, 1, 0.1);
    
  }
  
  /* CARRUSEL Y IMAGENES EN WEB */
  .service-carousel,
  .service-image-container {
    flex: 1;
    max-width: 50%;
    height: 350px;
  }
  
  .carousel-container, 
  .service-image-container img {
    width: 100%;
    height: 100%;
    
  }
  
  /* CONTENIDO */
  .service-content {
    flex: 1;
    padding: 20px;
  }
  
  .service-title {
    font-size: 14px;
    color: #1976D2;
    font-weight: bold;
  }
  
  .service-description {
    font-size: 14px;
    margin: 10px 0;
  }
  
  .service-list {
    list-style: none;
    list-style-type: disc;
    padding-left: 20px; /* importante para que se vea el bullet */
  }
  
  .service-list li {
    font-size: 14px;
    color: #3C3C3C;
    margin-bottom: 2px;
  }
  
  /* RESPONSIV */
  
  /* === TABLET === */
@media (max-width: 1024px) {
  .service-card {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0 24px; /* margen lateral */
  }

  .service-carousel,
  .service-image-container {
    max-width: 100%;
    height: auto;
  }

  .service-content {
    padding: 20px 0;
    width: 100%;
  }
}

/* === MÓVIL === */
@media (max-width: 768px) {
  .services-title {
    font-size: 1.8rem;
    text-align: left;
    padding: 0 24px;
  }

  .service-card {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0 20px;
  }

  .service-carousel,
  .service-image-container {
    width: 100%;
    height: auto;
  }

  .service-content {
    padding: 15px 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .service-content {
    padding: 10px 0;
  }

  .service-title {
    font-size: 1.3rem;
  }

  .service-description,
  .service-list li {
    font-size: 0.9rem;
  }
}
