.clientes_section_container {
    padding: 4vh 6vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 4vh;
  }
  
  .image_container img {
    width: 100%;
   
    object-fit: cover;
    max-width: 500px;
    border-radius: 8px;
    padding: center;
  }
  
  .clientes_container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }
  
  .clientes_title {
    font-family: var(--fuente-cuerpo);
    font-size: 39px;
    color: var(--primary-900);
    margin-bottom: 1.5vh;
    text-align: left;
  }
  
  .client_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: nowrap;
  }
  
  .client_image {
    width: 188px;
    height: auto;
    object-fit: contain;
  }
  
  .body_25 {
    font-family: var(--fuente-cuerpo);
    font-weight: 300;
    font-size: 25px;
    color: #333;
  }
  
  .clientes_section_container {
    padding: 4vh 6vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 4vh;
  }
  
  .image_container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 500px;
    border-radius: 8px;
  }
  
  .clientes_container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }
  
  .clientes_title {
    font-family: var(--fuente-cuerpo);
    font-size: 39px;
    color: var(--primary-900);
    margin-bottom: 1.5vh;
    text-align: left;
  }
  
  .client_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: nowrap;
  }
  
  .client_image {
    width: 188px;
    height: auto;
    object-fit: contain;
  }
  
  .body_25 {
    font-family: var(--fuente-cuerpo);
    font-weight: 300;
    font-size: 25px;
    color: #333;
  }
  
  
  /* ===== TABLET ===== */
  @media (max-width: 1024px) {
    .clientes_section_container {
      flex-direction: column;
      align-items: center;
      padding: 4vh 6vw;
      gap: 3vh;
    }
  
    .image_container {
      width: 50%;
      max-width: 100%;
        
    }
  
    .image_container img {
      width: 100%;
      height: 280px;
      object-fit: cover;
      border-radius: 8px;
    }
  
    .clientes_container {
      width: 100%;
      align-items: center;
    }
  
    .clientes_title {
      font-size: 32px;
      text-align: center;
    }
  
    .client_container {
      flex-direction: row; 
      justify-content: center;
      text-align: left;
    }
  
    .client_image {
      width: 140px;
    }
  
    .body_25 {
      font-size: 20px;
    }
  }
  
  /* ===== MÓVIL ===== */
  @media (max-width: 768px) {
    .clientes_section_container {
      flex-direction: column;
      align-items: center;
      padding: 4vh 5vw;
      gap: 3vh;
    }
  
    .image_container {
      width: 100%;
      max-width: 100%;
    }
  
    .image_container img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  
    .clientes_container {
      align-items: center;
    }
  
    .clientes_title {
      font-size: 26px;
      text-align: center;
    }
  
    .client_container {
      flex-direction: row; /* 👈🏼 mantiene fila horizontal */
      align-items: center;
      gap: 0.8rem;
    }
  
    .client_image {
      width: 100px;
    }
  
    .body_25 {
      font-size: 16px;
    }
  }
  