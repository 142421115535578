/* ===================== */
/*  ESTILOS GENERALES */
/* ===================== */
.quienes-somos-page {
    font-family: "Poppins", sans-serif;
    background-color: #ffffff;
    
  }
  
  /* ===================== */
  /*SECCIÓN PRINCIPAL */
  /* ===================== */
  .quienes-somos-header {
    text-align: center;
    padding: 20px;
    
  }
  
  /* ===================== */
/* SECCIÓN PRINCIPAL CON FONDO DE MARCA DE AGUA */
/* ===================== */
.quienes-somos-header {
  text-align: center;
  padding: 20px;

}
/* ============================= */
/* MARCA DE AGUA DETRÁS DEL TEXTO */
/* ============================= */
.marca-de-agua {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/imagenes/quienessomos/11.png"); /* logo */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40%; /* tamaño*/
  opacity: 0.3; /* opacidad */
  z-index: 0;
  pointer-events: none;
}


  .quienes-somos-title {
    font-size: 2rem;
    color:var(--primary-700);
    font-weight: bold;
  }

  .quienes-somos-subtitle {
    font-size: 1rem;
    color: var(--primary-700);
    font-weight: bold;
  }
  
  /* Ajuste de tamaño imágenes */
  .main-image,
  .carousel img {
    width: 80%;
    max-width: 900px;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
    margin: 20px auto;
    display: block;
  }
  .watermark-container {
    position: relative;
  }
  

  /* ===================== */
  /* SECCIONES GENERALES */
  /* ===================== */
  .section-title {
    text-align: center;
    font-size: 1.8rem;
    color: #1976D2;
    font-weight: bold;
    margin-top: 40px;
  }
  
  .section-description {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  /* ===================== */
  /* ESTILOS PARA JUSTIFICAR TEXTOS */
  /* ===================== */
  .quienes-somos-description,
  .quienes-somos-list,
  .section-description {
    text-align: justify;
    padding: 0 21%;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* ===================== */
  /* ESTILOS CARRUSEL */
  /* ===================== */
  .instalaciones-section,
  .equipo-section {
    text-align: center;
    padding: 20px;
  }
  
  .carousel img {
    width: 80%;
    max-width: 900px;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
    margin: auto;
  }

  /* ===================== */
  /* RESPONSIVE DESIGN */
  /* ===================== */
  
  /* MÓVIL (Menos de 768px) */
  @media (max-width: 768px) {
    .quienes-somos-description,
    .section-description {
      font-size: 0.95rem;
      padding: 0 10%;
    }
  
    .main-image,
    .carousel img {
      width: 90%;
      height: 300px;
    }
  }
  
  /* TABLET (Entre 768px y 1024px) */
  @media (max-width: 1024px) {
    .quienes-somos-description,
    .section-description {
      padding: 0 12%;
    }
  
    .main-image,
    .carousel img {
      width: 85%;
      height: 350px;
    }
  }
  
  /*  WEB (Más de 1024px) */
  @media (min-width: 1025px) {
    .quienes-somos-description,
    .section-description {
      padding: 0 20%;
    }
  
    .main-image,
    .carousel img {
      width: 80%;
      max-width: 900px;
      height: 400px;
    }
  }
  