.detalle_proyecto_container{
    max-width: 1200px; 
    margin: 0 auto;
    padding: 2vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
    justify-content: space-evenly;
    gap: 5vh;
}

.imagenes_proyecto_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    align-items: flex-start; 
    flex: 6; 
    max-width: 60%;
}

.proyecto_name{
    font-family: var(--fuente-cuerpo);
    font-size: 49px;
    font-weight: 700;
    color: var(--primary-900);
    white-space: nowrap;
    margin: 0;
    padding:1vh 6vw;

}


.detalles_proyecto_container { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5vh;
    flex: 4; 
    max-width: 40%;
}

.descripcion_proyecto_h2{
    font-family: var(--fuente-cuerpo);
    font-size: 31px;
    font-weight: 600;
    color: var(--primary-900);
    white-space: nowrap;
}

.detalle_proyecto_h3{
    font-family: var(--fuente-cuerpo);
    font-size: 25px;
    font-weight: 400;
    color: var(--secondary-600);
    white-space: nowrap;
}

.imagen_pr {
    width: 100%;
    height: auto; 
    object-fit: contain; 
}


/* TABLET: hasta 1024px */
@media (max-width: 1024px) {
    .detalle_proyecto_container {
      flex-direction: column;
      align-items: center;
      padding: 2vh 4vw;
      gap: 4vh;
    }
  
    .imagenes_proyecto_container,
    .detalles_proyecto_container {
      max-width: 90%;
      width: 100%;
    }
  
    .proyecto_name {
      font-size: 40px;
      text-align: center;
      white-space: normal;
      margin-top: 2vh;
    }
  
    .descripcion_proyecto_h2,
    .detalle_proyecto_h3 {
      white-space: normal;
    }
  }
  
  /* MOBILE: hasta 768px */
  @media (max-width: 768px) {
    .detalle_proyecto_container {
      flex-direction: column;
      align-items: center;
      padding: 2vh 5vw;
      gap: 3vh;
    }
  
    .imagenes_proyecto_container,
    .detalles_proyecto_container {
      max-width: 100%;
      width: 100%;
    }
  
    .proyecto_name {
      font-size: 32px;
      text-align: center;
      margin: 1vh 0;
      
    }
  
    .descripcion_proyecto_h2 {
      font-size: 24px;
    }
  
    .detalle_proyecto_h3 {
      font-size: 18px;
    }
  
    .descripcion_proyecto {
      font-size: 16px;
      line-height: 1.6em;
    }
  
    .imagen_pr {
      width: 100%;
      height: auto;
      margin-bottom: 1vh;
    }
  
    .galeria {
      width: 100%;
    }
  }
  .proyecto_name_container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 1vh 5vw; /* Puedes ajustar el padding lateral */
}


