/* SECCIÓN PROYECTOS */
.galeyproject-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 2rem 2rem;
    max-width: 1200px;
    margin: auto;
  }
  
  /* TARJETAS DE PROYECTOS */
  .project-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
    height: 400px;
  }
  
  .project-card:hover {
    transform: scale(1.05);
  }
  
  /* IMÁGENES */
  .project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  /* SUPERPOSICIÓN DE TEXTO */
  .project-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .project-card:hover .project-overlay {
    opacity: 1;
  }
  
  /* TEXTO DEL PROYECTO */
  .project-title {
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }