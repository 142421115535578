/* GOOGLE FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*Variables CSS*/

:root{

  /*Colores*/

  --white: #FAFAFA;
  --black: #000;
  --primary-700: #1976D2;
  --primary-900: #0D47A1;
  --primary-dark-900: #1A237E;
  --secondary-200: #D5F09B;
  --secondary-600: #A3CD28;
  --secondary-800: #879F10;
  --neutral-300: #F0F0F0;
  --neutral-500: #C2C2C2;
  --neutral-700: #818181;
  --neutral-900: #3C3C3C;
  --error-a700: #D50000;
  --advertencia-a400: #FFEA00;

  /* Tipografía*/

  --fuente-cuerpo: 'Poppins', sans-serif;

  /*Reseteo*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
}
